html,
body {
  width: 100%;
  height: 100%;
}
body {
  margin: 0;
}
* {
  box-sizing: border-box;
}
